import React, { ReactNode } from "react";
import { isEmpty } from "lodash";
import { BLOCKS } from "@contentful/rich-text-types";

import { EButtonSize } from "@components/Button/types";
import CtasCollection from "@components/ContentfulComponents/ComponentTextAssetAndCtas/CtasCollection/CtasCollection.component";
import {
	ETextAssetAndCtasCflBgColor,
	ETextAssetAndCtasCflTextAlignment,
	ITextAssetAndCtasCflData,
} from "@components/ContentfulComponents/ComponentTextAssetAndCtas/type";
import { TRichTextEntryNode } from "@shared/contentful/contentful.types";
import { useResponsive } from "@hooks/useDevice";
import { renderRichTextWithLinks } from "@shared/contentful/contentful-render.utils";

import styles from "@components/ContentfulComponents/ComponentTextAssetAndCtas/RichTextAndCtas/rich-text-and-ctas.module.scss";

const ParagraphTextHeading2 = (_node: TRichTextEntryNode, children: ReactNode) => {
	return <h2 className={styles.paragraphTextHeading2}>{children}</h2>;
};
const ParagraphTextHeading3 = (_node: TRichTextEntryNode, children: ReactNode) => {
	return <h3 className={styles.paragraphTextHeading3}>{children}</h3>;
};
const MultiContentParagraph = (_node: TRichTextEntryNode, children: ReactNode) => {
	return <p className={styles.multiContentParagraph}>{children}</p>;
};

const additionalRenderNodeOptions = {
	[BLOCKS.HEADING_2]: ParagraphTextHeading2,
	[BLOCKS.HEADING_3]: ParagraphTextHeading3,
	[BLOCKS.PARAGRAPH]: MultiContentParagraph,
};

type TRichTextAndCtas = Pick<
	ITextAssetAndCtasCflData,
	"textAlignment" | "backgroundColor" | "richTextContent" | "openLinksInANewTab" | "ctasCollection"
>;

const RichTextAndCtas = ({
	textAlignment,
	backgroundColor,
	richTextContent,
	openLinksInANewTab,
	ctasCollection,
}: TRichTextAndCtas) => {
	const { isMobile } = useResponsive();

	if (!richTextContent || isEmpty(richTextContent.json)) return null;

	const buttonSize = isMobile ? EButtonSize.Sm : EButtonSize.Md;

	return (
		<div className={"container-grid medium-grid"}>
			<div
				className={`${
					backgroundColor === ETextAssetAndCtasCflBgColor.LightPurple ? styles.bgLightPurple : ""
				} ${textAlignment === ETextAssetAndCtasCflTextAlignment.Center ? styles.textCenter : ""} ${
					styles.richTextAndCtasParagraph
				}`}
				data-testid="RichTextAndCtas_wrapper"
			>
				{renderRichTextWithLinks({
					richTextDocument: richTextContent.json,
					additionalRenderNodeOptions,
					openLinksInANewTab,
				})}
				<div className={styles.richTextAndCtasLinks}>
					<CtasCollection ctasCollection={ctasCollection} size={buttonSize} />
				</div>
			</div>
		</div>
	);
};

export default RichTextAndCtas;
